import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "welcome": "This is the website of Marc.",
      "contact": "Yes I like sushi. For other stuff consider to contact me on"
    }
  },
  fr: {
    translation: {
      "welcome": "Ce n'est pas la site web de Marc.",
      "contact": "Oui, j'aime les sushis. Pour d'autres choses, vous pouvez me contacter sur"
    }
  },
  de: {
    translation: {
      "welcome": "Das ist die Website von Marc.",
      "contact": "Ich mag Sushi. Für alles andere kontaktier mich auf"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;