import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';

const lngs = {
  en: { nativeName: 'EN' },
  fr: { nativeName: 'FR' },
  de: { nativeName: 'DE' },
};
const NavigationBar = () => {
  const { i18n } = useTranslation();

  return (
    <Navbar collapseOnSelect expand="lg" bg="" variant="dark" fixed="bottom">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='container justify-content-center align-items-center'>
            <Nav.Item >
              <ButtonGroup >
                {Object.keys(lngs).map((lng) => (
                  <Button active={i18n.resolvedLanguage === lng ? true : false} key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} variant='outline-light' type="submit" onClick={() => i18n.changeLanguage(lng)}>
                    {lngs[lng].nativeName}
                  </Button>
                ))}
              </ButtonGroup>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  )
};

export default NavigationBar;